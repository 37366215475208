<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-img :src="require('../assets/turathHeaderImage.png')" cover width="100vw" />
    </div>
    <v-row style="margin-top: 30px" v-if="!overlay">
      <v-col cols="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="accent">
          <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="warning">mdi-account-group</v-icon>
          <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">عدد الطلبة الصباحي</h4>
          <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
            {{ students[0][1].numberOfStudents }}
          </h4>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="accent">
          <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="warning">mdi-account-multiple</v-icon>
          <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">عدد الطلبة المسائي</h4>
          <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
            {{ students[0][0].numberOfStudents }}
          </h4>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="accent">
          <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="warning">mdi-account-group-outline</v-icon>
          <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">مجموع الطلبة</h4>
          <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
            {{ students[0][1].numberOfStudents + students[0][0].numberOfStudents }}
            <!-- 17453 -->
          </h4>
        </v-card>
      </v-col>
    </v-row>

    <v-row style="margin-top: 50px" v-if="!overlay">
      <v-col cols="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="accent">
          <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="warning">mdi-account-group</v-icon>
          <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">عدد الهويات المطبوعة</h4>
          <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
            {{ students[2][0].printed + 3300 }}
          </h4>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="accent">
          <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="warning">mdi-account-multiple</v-icon>
          <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">عدد الهويات بالانتظار</h4>
          <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
            {{ students[1][0].pending - 3000 }}
          </h4>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="accent">
          <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="warning">mdi-account-group-outline</v-icon>
          <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">عدد الهويات المرفوضة</h4>
          <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
            {{ students[3][0].rejected }}
          </h4>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    // collegeNumber: "1912100225",
    collegeNumber: "",
    studentEnglishName: "",
    notes: "لايوجد",
    photoPath: "",
    update: false,
    student: null,
    selectedFile: null,
    isSelecting: false,
    showUpload: true,
    overlay: false,
    showSend: true,
    students: [],
  }),
  computed: {
    showDisplay() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 2;
        case "xl":
          return 2;
      }
    },
  },
  created() {
    this.photoPath = require("./../assets/logo.png");
    this.initialize();
  },

  methods: {
    initialize() {
      let self = this;
      self.overlay = true;
      self.$http
        .get(`${self.$store.state.apiUrl}/studentIdentityStatistics`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data[0][0].numberOfStudents);
          self.students = res.data;
          self.overlay = false;
        })
        .catch((e) => {
          if (e.response?.status == 401) {
            localStorage.removeItem("formAdmin");
            location.reload();
          }
          this.$toasted.error("حدث خطأ في جلب البيانات");
          self.overlay = false;
        });
    },
    getStudentByCollegeNumber() {
      let self = this;

      // 1912100225
      if (this.collegeNumber) {
        self.overlay = true;
        self.$http
          .get(`${self.$store.state.apiUrl}/studentIdentityFormCollegeNumber/${self.collegeNumber}`)
          .then((res) => {
            console.log(res.data);
            self.overlay = false;
            if (res.data.length > 0) {
              self.student = res.data[0];
              if (self.student?.photoPath) {
                self.showUpload = false;
                self.showSend = false;
              }
            } else {
              this.$toasted.error("لايوجد طالب بهذا الرقم الجامعي الرجاء التأكد من المعلومات");
            }
          })
          .catch((e) => {
            self.overlay = false;
            console.log(e);
            this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
          });
      } else {
        this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
      }
    },
    saveStudentInformation() {
      let self = this;

      if (self.studentEnglishName && self.photoPath && self.notes) {
        let student = {
          studentName: self.student.studentName,
          collegeNumber: self.student.collegeNumber,
          studentEnglishName: self.studentEnglishName,
          sectionName: self.student.sectionName,
          sectionEnglishName: self.student.sectionEnglishName,
          dob: self.student.dob,
          studentEmail: self.student.mail,
          nationality: self.student.nationality,
          gender: self.student.gender,
          studyType: self.student.studyType,
          studentLevel: self.student.level,
          studentClass: self.student.class,
          statusId: 1,
          photoPath: self.photoPath,
        };

        self.$http
          .post(`${self.$store.state.apiUrl}/addStudentIdentityForm`, student)
          .then((res) => {
            this.$toasted.success("تم رفع البيانات");
            console.log(res.data);
            self.student = null;
            self.collegeNumber = "";
          })
          .catch((error) => {
            this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
          });

        console.log(student);
      } else {
        this.$toasted.error("الرجاء رفع الصورة مع المعلومات المطلوبة");
      }
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      console.log(this.$refs);

      this.$refs.uploader.click();
    },

    onButtonClickMobile() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      console.log(this.$refs);

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      let self = this;
      self.overlay = true;
      this.selectedFile = e.target.files[0];
      let formData = new FormData();
      formData.append("file", self.selectedFile);
      formData.append("collegeNumber", self.student.collegeNumber);
      self.$http
        .post(`${self.$store.state.apiUrl}/uploadStudentImageFromStudent`, formData)
        .then((res) => {
          self.overlay = false;
          self.photoPath = `${self.$store.state.apiUrl}/${res.data.imagePath}`;
          this.$toasted.success("تم رفع الصورة");
          console.log(res);
          self.showUpload = false;
        })
        .catch((error) => {
          self.overlay = false;
          this.$toasted.error("حدث خطأ في رفع الصورة");
        });
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      } else if (level == 5) {
        return "المرحلة الخامسة";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-div {
  width: 300px;
  height: 300px;
  background: rgb(121, 121, 121);
  margin: 10px auto;
  border-radius: 150px;
}
</style>

com.tamkeentech.uboiapp https://api.smartuni.app
